* {
  margin: 0;
  box-sizing: border-box;
}

blockquote,
body,
button,
dd,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
input,
legend,
li,
ol,
p,
pre,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
}

p {
  /* display: block; */
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

/* coommon */
.mgt10 {
  margin-top: 10px;
}
.mgt20 {
  margin-top: 20px;
}
.mgt30 {
  margin-top: 30px;
}
.mgt40 {
  margin-top: 40px;
}
.mgt50 {
  margin-top: 50px;
}
.mgb10 {
  margin-bottom: 10px;
}
.mgb20 {
  margin-bottom: 20px;
}
.mgb30 {
  margin-bottom: 30px;
}
.mgb40 {
  margin-bottom: 40px;
}
.mgb50 {
  margin-bottom: 50px;
}
.mgl10 {
  margin-left: 10px;
}
.mgl20 {
  margin-left: 20px;
}
.mgl30 {
  margin-left: 30px;
}
.mgl40 {
  margin-left: 40px;
}
.mgl50 {
  margin-left: 50px;
}
.mgl60 {
  margin-left: 60px;
}
.mgr10 {
  margin-right: 10px;
}
.mgr20 {
  margin-right: 20px;
}
.mgr30 {
  margin-right: 30px;
}
.mgr40 {
  margin-right: 40px;
}
.mgr50 {
  margin-right: 50px;
}
.mgr60 {
  margin-right: 60px;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
@font-face {
  font-family: 'DINAlternateBold';
  src: url('https://cdn.ywwl.com/libs/fonts/DINAlternateBold.ttf');
}

body {
  font-family: "-apple-system", "BlinkMacSystemFont", "Segoe UI, Roboto",
    "Helvetica Neue", "Arial", "Noto Sans", "sans-serif", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin: 0;
}
