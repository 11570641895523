
@font-face {
  font-family: 'DINAlternateBold';
  src: url('https://cdn.ywwl.com/libs/fonts/DINAlternateBold.ttf');
}
@font-face {
  font-family: 'Alibaba-PuHuiTi';
  src: url('https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/Font/Alibaba-PuHuiTi-Regular.otf');
}
@font-face {
  font-family: 'Alibaba-PuHuiTi-Bold';
  src: url('https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/Font/Alibaba-PuHuiTi-Bold.otf');
}
@font-face {
  font-family: 'Alibaba-PuHuiTi-Heavy';
  src: url('https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/Font/Alibaba-PuHuiTi-Heavy.otf');
}
@font-face {
  font-family: 'Alibaba-PuHuiTi-Light';
  src: url('https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/Font/Alibaba-PuHuiTi-Light.otf');
}
@font-face {
  font-family: 'Alibaba-PuHuiTi-Medium';
  src: url('https://yw-fed-static.oss-cn-hangzhou.aliyuncs.com/marketing/chaoliu/Font/Alibaba-PuHuiTi-Medium.otf');
}

.ywd_h5_toast .adm-auto-center-content {
  font-size: 24px;
}

:root:root .adm-picker {
  --title-font-size: 32px;
  --header-button-font-size: 32px;
  ---item-font-size: 28px;
  ---item-height: 50px;
  height: 450px;
}

/* tabs */
:root:root .adm-tabs-header {
  border: none;
}

:root:root .adm-tabs-tab {
  font-size: 30px;
  font-weight: 400;
  color: #666666;
  line-height: 1;
  padding: 0 0 20px 0;
}

:root:root .adm-tabs-tab.adm-tabs-tab-active {
  font-weight: 500;
  color: #000000;
}

:root:root .adm-tabs-tab-line {
  width: 30px !important;
  height: 4px;
  border-radius: 0;
}
/* dropdown */
:root:root .adm-dropdown-popup .adm-dropdown-item-content{
  padding-top: 12px;
}

:root:root .adm-dropdown-popup .adm-list-body {
  border: none;
  max-height: 518px;
  overflow-y: auto;
}

:root:root .adm-dropdown-popup .adm-list-item{
  padding: 0 30px;
}

:root:root .adm-dropdown-popup .adm-list-item-content-main{
  font-size: 28px;
  line-height: 1.5;
  padding: 16px 0;
}

:root:root .adm-dropdown-popup .adm-list-item-content-main .antd-mobile-icon {
  margin-right: 12px;
}

:root:root .adm-dropdown-popup .adm-list-item.active .adm-list-item-content-main{
  font-weight: 500;
  color: #3978FB;
}

:root:root .adm-picker-header-button {
  padding-left: 30px;
  padding-right: 30px;
}

div, p, ul, li, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}

ul, li {
  list-style: none;
}
